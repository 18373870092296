import { gql } from "@apollo/client";

export const dashboard_query = gql`
  query getDashboardCount {
    getDashboardCount {
      total_vehicles
      allotted
      not_allotted
      on_service
      condemned
      department_on_service
      error {
        status_code
        message
      }
    }
  }
`;

export const dashboard_fuel_query = gql`
  query fuelDashboardDetails {
    fuelDashboardDetails {
      this_week_amount
      this_week_quantity
      this_month_quantity
      this_month_amount
      previous_month__quantity
      previous_month_amount
    }
  }
`;
export const department_dashboard_query = gql`
  query departmentWiseVehicleCount {
    departmentWiseVehicleCount {
      items {
        department_id
        name
        vehicle_count
        error {
          status_code
          message
        }
      }
    }
  }
`;
export const technical_person_dashboard_query = gql`
  query {
    serviceRequestDashboard {
      items {
        name
        count
        error {
          status_code
          message
        }
      }
    }
  }
`;
