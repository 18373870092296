import { dashboardReducer } from "./dashboard";
import { dashboard_fuelReducer } from "./dashboard_fuel";
import { department_dashboardReducer } from "./department_dashboard";
import { technicalPersonDashboardReducer } from "./technical_person_dashboard";
export const dashboardReducers = {
  dashboard: dashboardReducer,
  dashboard_fuel: dashboard_fuelReducer,
  department_dashboard: department_dashboardReducer,
  technicalPersonDashboard: technicalPersonDashboardReducer,
};
export * from "./dashboard";
export * from "./dashboard_fuel";
export * from "./department_dashboard";
export * from "./technical_person_dashboard";
