import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "../../../apollo/api_service";
import { getOTPMutation, verifyOTPQuery } from "./graphql";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
  verifyStatus: null,
};

const slice = createSlice({
  name: "refugeeLogin",
  initialState,
  reducers: {
    _refugeeLogin: (state) => {
      state.loading = true;
    },
    _refugeeLoginSuccess: (state, { payload }) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = payload.status;
    },
    _refugeeLoginFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
      state.status = payload.status;
    },
    _verifyOTPSuccess: (state, { payload }) => {
      localStorage.setItem("user", JSON.stringify(payload));
      localStorage.setItem("currentLanguage", "en");

      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.verifyStatus = payload.userId ? "Success" : "Failure";
    },
    _verifyOTPFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
    },
  },
});

const {
  _refugeeLogin,
  _refugeeLoginSuccess,
  _refugeeLoginFailure,
  _verifyOTPSuccess,
  _verifyOTPFailure,
} = slice.actions;

export const refugeeLoginSelector = (state) => state.refugeeLogin;

export const refugeeLoginReducer = slice.reducer;

export function getOTPRequest(variables) {
  return async (dispatch) => {
    dispatch(_refugeeLogin());
    try {
      const response = await MutateRequest(getOTPMutation, variables, dispatch);
      if (response?.data?.getOTP && !response?.data?.getOTP.error) {
        dispatch(_refugeeLoginSuccess(response?.data?.getOTP));
      } else if (response?.data?.getOTP?.error) {
        dispatch(_refugeeLoginFailure(response?.data?.getOTP.error));
      }
    } catch (error) {
      dispatch(_refugeeLoginFailure(error));
    }
  };
}

export function verifyOTPRequest(variables) {
  return async (dispatch) => {
    dispatch(_refugeeLogin());
    try {
      const response = await MutateRequest(verifyOTPQuery, variables, dispatch);
      if (response?.data?.verifyOTP && !response?.data?.verifyOTP.error) {
        dispatch(_verifyOTPSuccess(response?.data?.verifyOTP));
      } else if (response?.data?.verifyOTP?.error) {
        dispatch(_verifyOTPFailure(response?.data?.verifyOTP.error));
      }
    } catch (error) {
      dispatch(_verifyOTPFailure(error));
    }
  };
}
